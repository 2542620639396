import logo from '../img/logo6b.png'
import Navbar from './Navbar'
import './Header.css'
import menuOn from '../img/menuon.png'
import menuOff from '../img/menuoff.png'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export default function Header() {
  const [isHamburgerOn, setIsHamburgerOn] = useState(true)
  return (
    <header className='header'>
      <img src={logo} alt='logo'/>
      <Navbar />
      <div className='hamburger-menu-container'>
        <button onClick={() => setIsHamburgerOn(!isHamburgerOn)} className='hamburger-menu-button'>
          <img 
            src={isHamburgerOn? menuOn : menuOff} 
            alt='hamburger menu' 
            className='hamburger-menu'
          />
        </button>
        {!isHamburgerOn && (
          <ul className='hamburger-menu-ul'>
            <li><Link to='/'>HOME</Link></li>
            <li><Link to='/curriculum'>CURRICULUM</Link></li>
            <li><Link to='/portfolio'>PORTFOLIO</Link></li>
            <li><Link to='/contatti'>CONTATTI</Link></li>
          </ul>
        )}
      </div>
    </header>
  )
}
