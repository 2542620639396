import './Curriculum.css'
import { useEffect, useState } from 'react'
import { useFetch } from '../hooks/useFetch'
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom"
/* import certifications from '../lists/certifications' */

export default function Curriculum() {
  /* const { certificationsList } = certifications() */
  const { data, isPending, error } = useFetch('/jsonLists/certifications.json')
  const { data: data2, isPending: isPending2, error: error2 } = useFetch('/jsonLists/books.json');
  const { data: data3, isPending: isPending3, error: error3 } = useFetch('/jsonLists/presentazione.json');
  const [certOpen, setCertOpen] = useState(true);
  const [booksOpen, setBooksOpen] = useState(true);
  useEffect(() => {
    document.querySelector('.current-page').classList.remove('current-page')
    document.querySelector('#nav-curriculum').classList.add('current-page')
  }, [])
  console.log(data, 'data')
    const certificationsList = data ? data.certifications : null
    const books = data2 ? data2.books : null
    console.log(certificationsList, "certificationsList")
  return (
    <main className="curriculum-main radius-shadow">
    <h1 id='curriculum-start'>Curriculum vitae</h1>
    <article className="curriculum">
      <h2>Sommario</h2>
      <section className='cv-sommario-container'>
        <ol className='cv-sommario'>
          <li><a href='#pres'>Presentazione</a></li>
          <li><a href='#comp-tec'>Competenze Tecniche</a></li>
          <li><a href='#ist-inf'>Istruzione Informatica</a></li>
          <li><a href='#esp-pro'>Esperienza Professionale</a></li>
          <li><a href='#lin-str'>Lingue Straniere</a></li>
        </ol>
        <ol className='cv-sommario' start={6}>
          <li><a href='#sof-ski'>Soft Skill</a></li>
          <li><a href='#ist-alt'>Istruzione (Altro)</a></li>
          <li><a href='#int-hob'>Interessi e Hobby</a></li>
          <li><a href='#ult-inf'>Ulteriori Informazioni</a></li>
          <li><a href="/Yari_Piras_-_Frontend_Developer-2024.pdf" download>Download CV</a></li>
        </ol>
      </section>

      <a href='#curriculum-start' className='redirect' id='pres'><i>Torna su</i></a>
      <h2 >Presentazione</h2>
        {isPending3 && <p>Loading...</p>}
        {error3 && <p>{error3}</p>}
        {data3 && <p>{data3.presentazione}</p>}
      <hr />

      <a href='#curriculum-start' className='redirect' id='comp-tec'><i>Torna su</i></a>
      <h2 >Competenze Tecniche:</h2>
      <ul>
        <li>
          <h3>Linguaggi di Programmazione:</h3>
          <ul>
            <li>Ottimo: JavaScript, HTML, CSS, XML, JSON</li>
            <li>Buono: Node.js, Git, TypeScript, Sass</li>
            <li>Base: PHP</li>
          </ul>
        </li>
        <li>
          <h3>Framework e Librerie:</h3>
          <ul>
            <li>Ottimo: React, React.Native, Next.JS, AJAX, Firebase, MongoDB</li>
            <li>Buono: Bootstrap, Leaflet, Canvas, SVG, Web-Audio API, Stripe, Sanity</li>
            <li>Base: Angular, JQuery </li>
          </ul>
        </li>
        <li>
          <h3>Paradigmi di Programmazione:</h3>
          <ul>
            <li>Object-Oriented Programming (OOP)</li>
            <li>Programmazione Funzionale</li>
          </ul>
        </li>
        <li>
          <h3>Altro:</h3>
          <ul>
            <li>Conoscenza della logica algoritmica e degli algoritmi base</li>
            <li>Conoscenza di Bash e DOS</li>
            <li>Conoscenza dei principi di Object-Oriented Design (OOD) e Design Patterns</li>
            <li>Conoscenza di UI/UX (User Interface/User Experience) design</li>
            <li>Familiarità con l'accessibilità web (ARIA - Accessible Rich Internet Applications)</li>
            <li>Conoscenza del protocollo MIDI</li>
          </ul>
        </li>
        <li>
          <h3>Software:</h3>
          <ul>
            <li>Strumenti di Sviluppo: Visual Studio Code, Notepad++, MIT App Inventor, XAMPP</li>
            <li>Grafica e Design: Figma, Canva, Adobe Illustrator, Scratch</li>
            <li>CMS: WordPress, Wix, One</li>
            <li>Suite Office, Browser</li>
          </ul>
        </li>
      </ul>
      <hr />

      <a href='#curriculum-start' className='redirect' id='ist-inf'><i>Torna su</i></a>
      <h2>Istruzione Informatica: </h2>
      <ul>
        <li>Diploma di Liceo Scientifico ad Indirizzo Informatico presso il Liceo "A. Einstein" di Milano</li>
        <li>
          <FontAwesomeIcon 
            icon={certOpen? faMinusCircle: faPlusCircle} 
            size="1x" 
            color={certOpen? "red": "green"} 
            style={{
              cursor: 'pointer', 
              marginRight: '0.5em', 
              border: '1px solid white', 
              borderRadius: '50%', 
              backgroundColor: 'white'}}
            onClick={() => setCertOpen(!certOpen)}
          />Certificazioni:
          {certOpen && (<ul className='certificazioni'>
            {isPending && <p>Loading...</p>}
            {error && <p>{error}</p>}
            {certificationsList && certificationsList.map((certification, index) => {
              return <li key={'cert'+index}><a href={certification.link} target='_blank' rel='noreferrer'>{certification.name}</a></li>
            })}
          </ul>)}
        </li>
        <li>
          <FontAwesomeIcon 
            icon={booksOpen? faMinusCircle: faPlusCircle} 
            size="1x" 
            color={booksOpen? "red": "green"} 
            style={{
              cursor: 'pointer', 
              marginRight: '0.5em', 
              border: '1px solid white', 
              borderRadius: '50%', 
              backgroundColor: 'white'}}
            onClick={() => setBooksOpen(!booksOpen)}
          />Preparazione su numerosi testi specialistici, sia in Italiano che in Inglese:
          {booksOpen && (<ul className='libri'>
            {isPending2 && <p>Loading...</p>}
            {error2 && <p>{error2}</p>}
            {books && books.map((book, index) => {
              return <li key={'lib'+index}>
                  {book.author},&nbsp;<i>{book.title}</i>
                </li>
            })}
          </ul>)}
        </li>
      </ul>
      <hr />
  
      <a href='#curriculum-start' className='redirect' id='esp-pro'><i>Torna su</i></a>
      <h2>Esperienza Professionale:</h2>
      <ul>
        <li>Sviluppo di diversi software per siti web utilizzando varie tecniche e linguaggi</li>
        <li>Collaborazione per l'aggiornamento quotidiano della homepage di Libero.it</li>
        <li>Webmaster freelance negli ultimi 4 anni</li>
        <li>Ideazione e sviluppo di nuove e originali applicazioni web, con una particolare inclinazione verso la musica e la multimedialità</li>
        <li>Decennale lavoro con numerose tipologie di software per la creazione di opere artistiche e non</li>
      </ul>
      <p className='esp-pro-redirect'>Si rimanda alla pagina&nbsp;
        <Link to={'/portfolio'}>Portfolio</Link>
      &nbsp;di questo sito e al mio&nbsp;
        <a href='https://github.com/Arboricum/' target='_blank' rel='noreferrer'>profilo GitHub</a>
      </p>
      <hr />
   
      <a href='#curriculum-start' className='redirect' id='lin-str'><i>Torna su</i></a>
      <h2>Lingue Straniere:</h2>
      <ul>
        <li>Inglese: Buona conoscenza parlata, ottima scritta</li>
      </ul>
      <hr />
   
      <a href='#curriculum-start' className='redirect' id='sof-ski'><i>Torna su</i></a>
      <h2>Soft Skills:</h2>
      <ul>
        <li>Facilità di apprendimento</li>
        <li>Eccellenti doti comunicative</li>
        <li>Capacità di problem-solving</li>
        <li>Orientato al lavoro in squadra</li>
        <li>Adattabilità e flessibilità</li>
        <li>Capacità di gestire lo stress e lavorare sotto pressione</li>
        <li>Creatività e pensiero innovativo</li>
        <li>Organizzazione e gestione del tempo</li>
      </ul>
      <hr />
          
      <a href='#curriculum-start' className='redirect' id='ist-alt'><i>Torna su</i></a>
      <h2>Istruzione (Altro):</h2>
      <ul>
        <li>Laurea Specialistica in Lettere con il massimo dei voti presso l'Università degli Studi di Milano</li>
      </ul>
      <hr />
          
      <a href='#curriculum-start' className='redirect' id='int-hob'><i>Torna su</i></a>
      <h2>Interessi e Hobby:</h2>
      <ul>
        <li>Studi di composizione sperimentale al Conservatorio di Milano</li>
        <li>Polistrumentista</li>
        <li>Canali su YouTube, Spotify e SoundCloud</li>
        <li>Pubblicazione di libri di poesie e racconti</li>
        <li>Appassionato giocatore e studioso di scacchi</li>
      </ul>
      <hr />

      <a href='#curriculum-start' className='redirect' id='ult-inf'><i>Torna su</i></a>
      <h2>Ulteriori Informazioni:</h2>
      <ul>
        <li>In parallelo all'attività professionale, sto sviluppando una significativa esperienza nell'utilizzo di ChatGPT, un modello di linguaggio AI avanzato. Questa esperienza sta ampliando la mia comprensione delle potenzialità delle tecnologie emergenti e la loro applicazione pratica</li>
      </ul>
      <hr />
    </article>
  </main>
  )
}
