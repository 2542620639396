import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
//images
import rightImg from '../img/home-prof3.png';
//import leftImg from '../img/OIG4.jfif';
import leftImg from '../img/io5.JPG';
//image second section
import monitorBg from '../img/bg_control_2.jpg'
//stack tecno
/* import jsIcon from '../img/logo-javascript.svg';
import cssIcon from '../img/css-3.svg';
import htmlIcon from '../img/html-1.svg';
import firebaseIcon from '../img/firebase-2.svg';
import reactIcon from '../img/react-1.svg';
import reactNatIcon from '../img/react-native-1.svg';
import nodeIcon from '../img/nodejs-icon.svg';
import electronIcon from '../img/electron-1.svg'; */
import { useFetch } from '../hooks/useFetch';

/* const icons = [
  { name: "Javascript", src: jsIcon },
  { name: "CSS", src: cssIcon },
  { name: "HTML", src: htmlIcon },
  { name: "Firebase", src: firebaseIcon },
  { name: "React", src: reactIcon },
  { name: "React.Native", src: reactNatIcon },
  { name: "Node.JS", src: nodeIcon },
  { name: "Electron", src: electronIcon }
]; */

export default function Home() {
  const navigate = useNavigate();
  const { data, isPending, error } = useFetch('/jsonLists/icons.json')

  console.log(data, 'data')
    const icons = data ? data.iconsList : null
    console.log(icons, "icons")

  useEffect(() => {
    document.querySelector('.current-page').classList.remove('current-page')
    document.querySelector('#nav-home').classList.add('current-page')
  }, [])

  useEffect(() => {
    const resetAnimation = () => {
      const elements = document.querySelectorAll('.typewriter');
      elements.forEach((element) => {
        element.style.animation = 'none';
        // Force reflow
        element.getBoundingClientRect();
        element.style.animation = '';
      });
    };

    const interval = setInterval(resetAnimation, 12000); // 15 secondi

    return () => clearInterval(interval); // Cleanup function
  }, []);

  return (
    <>
      <main className='home-main radius-shadow'>
        <img src={leftImg} alt='presentazione' className='home-leftImg'/>
        <div>
          <img src={rightImg} alt='presentazione' />
          <button className='cta-button' onClick={() => navigate('/contatti')}>Contattami!</button>
        </div>
      </main>

      <section className='first-home-section radius-shadow'>
        <div className='first-home-section-inside'>
          {isPending && <p>Loading...</p>}
          {error && <p>{error}</p>}
          {icons && icons.map((icon, index) => {
            return (
              <div key={icon.name + index} className='icon-container'>
                <img src={`/images/icons/${icon.src}`} alt={icon.name} className='icon-stack' />
                <span>{icon.name}</span>
              </div>
            );
          })}
        </div>
      </section>

      <section className='second-home-section radius-shadow'>
        <img src={monitorBg} alt='monitor background' className='monitor-bg'/>
        <div className='typewriter-container'>
          <p className="typewriter typewriter1">Creazione di siti web personali e professionali</p>
          <p className="typewriter typewriter2">E-commerce con Next.js e Stripe</p>
          <p className="typewriter typewriter3">App desktop con Javascript ed Electron</p>
          <p className="typewriter typewriter4">App multi-piattaforma con React.Native</p>
        </div>
      </section>
    </>
  );
}
